import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {Player, AbstractTeamType, Transfer} from './../../types';
import {
	Title, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	TeamOverview, Button, AbstractTeam, TransfersList
} from './../../components';
import {Overview, Value, Label} from '../../components/TeamOverview/TeamOverviewStyle';
import {Icon} from 'antd';

const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/goudenspelen-cycling-lineup.png';
import {startingListToPositionsList, roundNextHalf} from './../../lib/helpers';
import {pick} from 'lodash';
import * as teamsActions from '../../actions/teams'

import {Element, scroller} from 'react-scroll'

export interface Props {
	match: any;
}

export interface State {
	notFound: boolean;
	performingTransfer: boolean;
	performingTransferReset: boolean;
}

class TransfersContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
			performingTransfer: false,
			performingTransferReset: false
		}
	};

	onPlaceHolderClick = (e: any) => {
		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	componentDidMount() {
		if (this.props.visibleWeekId) {
			this.loadTeam();
		}
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.visibleWeekId && (this.props.visibleWeekId !== prevProps.visibleWeekId)) {
			this.loadTeam();
		}
	}

	loadTeam = () => {
		const teamId = this.props.match.params.id;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname'];

		const selectionProps: any[] = [];

		return teamsActions.get(teamId)
			.then(result => {
				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => Object.assign({inStarting: true}, pick(player, playerProps), pick(player.selection, selectionProps)));
				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => Object.assign({inStarting: false}, pick(player, playerProps), pick(player.selection, selectionProps)));
				const team = result.team;
				const teamName = result.team.name;

				const captainId = result.players
					.find((player: any) => player.selection.captain === 1)
					.id;

				const pastTransfers = !result.transfers ? ([] as Transfer[]) :
					result.transfers
						.filter((transfer: any) => this.props.matches.info.deadlineWeek && (transfer.weekId < this.props.matches.info.deadlineWeek))
						.map((transfer: any) => ({inId: transfer.inId, outId: transfer.outId, weekId: transfer.weekId}));

				const deadlineWeekTransfers = !result.transfers ? ([] as Transfer[]) :
					result.transfers
						.filter((transfer: any) => transfer.weekId === this.props.matches.info.deadlineWeek)
						.map((transfer: any) => ({inId: transfer.inId, outId: transfer.outId, weekId: transfer.weekId}));


				const getPlayersValuesWithTransfers = (players: any) => {
					const playersValue = players
						.reduce((acc: any, player: any) => {
							const wasTransferred = result.transfers.find((transfer: any) => transfer.inId === player.id);

							const playerValue = wasTransferred ?
								roundNextHalf(player.value + (player.value * (this.props.application.competition.transferTaxPercentage || 0) / 100)) :
								player.value;

							return acc + playerValue;
						}, 0);
					return this.props.application.competition.budget - playersValue;
				};

				const budget = result.team.budget !== null ?
					result.team.budget : getPlayersValuesWithTransfers(result.players)
				;

				this.props.initTeamState(starting, bench, team, teamName, captainId, budget, undefined, undefined, undefined, result.transfers, deadlineWeekTransfers, pastTransfers);
			})
			.catch(error => {
				this.setState({notFound: true});
			})
	};

	formatTransfers = (transfer: any) => {
		return {
			inPlayer: this.props.players.data.find((player: any) => player.id === transfer.inId),
			outPlayer: this.props.players.data.find((player: any) => player.id === transfer.outId),
			weekId: transfer && transfer.weekId
		}
	};

	onLineupPlayerOut = (player: Player) => {
		this.props.removeStartingPlayer(player);
		this.props.onTransferPlayerOut(player);

	};

	onBenchPlayerOut = (player: Player) => {
		this.props.removeBenchPlayer(player);
		this.props.onTransferPlayerOut(player);
	};

	onPlayerIn = (player: Player) => {
		this.props.pickPlayer(player, true);
		this.props.onTransferPlayerIn(player);
	};

	submitTransfers = (teamId: number) => {
		this.setState({performingTransfer: true});
		this.props.onTransfersSubmit(teamId)
			.then(() => this.loadTeam())
			.then(() => {
				this.setState({performingTransfer: false});
			})
	};

	resetTransfers = (teamId: number) => {
		this.setState({performingTransferReset: true});
		this.props.onTransfersReset(teamId)
			.then(() => this.loadTeam())
			.then(() => {
				this.setState({performingTransferReset: false});
			});
	};

	render() {
		const {
			application, starting, bench, budget, matches, user, initializedExternally, deadlineWeekTransfers,
			pastTransfers, draftTransfers, players, team
		} = this.props;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows);
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => !!player);
		const benchPicked = bench
			.filter(player => !!player);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;

		const firstPlayingWeekPassed = team && team.id && matches && matches.info && matches.info.deadlineWeek
			&& (matches.info.deadlineWeek > team.weekId);
		const notTeamOwner = team && team.userId && user.data && (team.userId !== user.data.id);

		const pastTransfersFormatted = pastTransfers
			.map(this.formatTransfers);

		const deadlineWeekTransfersFormatted = deadlineWeekTransfers
			.concat(draftTransfers)
			.map(this.formatTransfers);

		const canSaveDraftTransfers = draftTransfers
			.filter(draftTransfer => !!draftTransfer.inId && !!draftTransfer.outId)
			.length === draftTransfers.length;

		const remainingTransfers = application.competition.transfersAllowed - (deadlineWeekTransfersFormatted.length + pastTransfersFormatted.length);

		const canTransferOut = (remainingTransfers > 0) &&
			(deadlineWeekTransfersFormatted.length < application.competition.transfersAllowed);

		const draftPlayerInIds = draftTransfers
			.map(transfer => transfer.inId)
			.filter(inId => inId !== null);

		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';

		return (
			<React.Fragment>
				{(notTeamOwner || this.state.notFound) && <Redirect
					to={{pathname: `/home`}}
				/>}
				{team && this.props.user.leaguesFetched && !firstPlayingWeekPassed && <Redirect
					to={{pathname: `/edit/${team.id}`}}
				/>}
				{
					(initializedExternally && <Row>
						<Col md={12} sm={12} xs={24}>
							<Block>
								<Title type={TitleType.h2}>Transfers</Title>
								{
									(
										pastTransfersFormatted && pastTransfersFormatted.length &&
										<React.Fragment>
											<div style={{textAlign: 'center'}}>
												<Title type={TitleType.h4}>History</Title>
											</div>

											<TransfersList
												data={pastTransfersFormatted}
												showHeader={true}
												showWeek={true}
												size={5}/>
										</React.Fragment>
									) || null
								}

								<React.Fragment>
									<div style={{textAlign: 'center'}}>
										<Title type={TitleType.h4}>{`Speeldag ${matches.info.deadlineWeek} transfers`}</Title>
									</div>

									<TransfersList
										data={deadlineWeekTransfersFormatted}
										showHeader={true}
										tax={application.competition.transferTaxPercentage}
										size={5}/>

									<div style={{textAlign: 'center', paddingTop: '5px'}}>
										{
											(draftTransfers && draftTransfers.length && canSaveDraftTransfers &&
												<span style={{padding: '5px'}}>
													<Button
														onClick={(e: any) => this.submitTransfers(team.id)}
														disabled={this.state.performingTransfer}
														loading={this.state.performingTransfer}
														size="default">
														{
															(!this.state.performingTransfer &&
																<Icon type="save" theme="filled"/>
															) || null
														}
														Bevestig
													</Button>
												</span>) || null
										}

										{
											(draftTransfers && draftTransfers.length &&
												<span style={{padding: '5px'}}>
													<Button
														onClick={(e: any) => this.props.onDraftTransfersClear()}
														size="default">
														<Icon type="close-circle" theme="filled"/>
														Annuleer
													</Button>
												</span>) || null
										}

										{
											(deadlineWeekTransfers && deadlineWeekTransfers.length &&
												<span style={{padding: '5px'}}>
													<Button
														disabled={this.state.performingTransferReset}
														loading={this.state.performingTransferReset}
														onClick={(e: any) => this.resetTransfers(team.id)}
														size="default">
														{
															(!this.state.performingTransferReset
																&& <Icon type="delete" theme="filled"/>
															) || null
														}
														Reset
													</Button>
												</span>) || null
										}
									</div>
									<Row>
										<Col span={20}>
											<Label>
												Resterende transfers:
											</Label>
										</Col>
										<Col span={4}>
											<Value>
												<div style={{textAlign: 'right'}}>{remainingTransfers}</div>
											</Value>
										</Col>
									</Row>
								</React.Fragment>
							</Block>

							<Block>
								<Title type={TitleType.h2}>Selectie</Title>
								<TeamOverview budget={budget}
															totalPlayers={totalPlayersToPick}
															showRemainingTransfers={false}
															remainingTransfers={remainingTransfers}
															totalPlayersSelected={totalPlayersPicked}/>
								<Team widthRatio={12}
											heightRatio={10}
											clubs={this.props.clubs.data}
											modalEnabled={false}
											bg={teamBackground}
											selection={startingByPositions}
											assetsCdn={application.competition.assetsCdn}
											playerType={PlayerType.Cycling}
											playerBadgeColor="#000"
											playerBadgeBgColor="#fff"
											showPlayerValue={true}
											onRemove={canTransferOut && this.onLineupPlayerOut}
											actionLessPlayerIds={draftPlayerInIds}
											onPlaceholderClick={this.onPlaceHolderClick}
											playerPointsColor="#000"
											playerPointsBgColor="#84FF00"/>
								{	(this.props.application.competition.benchSize) ?
									<Substitutes
										selection={bench}
										clubs={this.props.clubs.data}
										title="De bus"
										bgColor="#F0F3F8"
										playerType={PlayerType.Cycling}
										assetsCdn={application.competition.assetsCdn}
										onPlaceholderClick={this.onPlaceHolderClick}
										showPlayerValue={true}
										playerBadgeColor="#000"
										playerBadgeBgColor="#ddd"
										playerPointsColor="#000"
										playerPointsBgColor="#292929"
										onRemove={canTransferOut && this.onBenchPlayerOut}
										actionLessPlayerIds={draftPlayerInIds}
									/>
									: null
								}
							</Block>
						</Col>
						<Col md={12} sm={12} xs={24}>
							<Block>
								<Element name="all-players">
									<Title type={TitleType.h2}>Alle Renners</Title>
									<PlayerList
										clubs={this.props.clubs.data}
										isLoading={this.props.players.isFetching}
										hidePositions={true}
										assetsCdn={application.competition.assetsCdn}
										isPickAble={(player: Player) => this.props.isPickAble(player, true, true)}
										playerType={PlayerType.Cycling}
										actionLabel={'Transfer'}
										data={players.data}
										playerTax={application.competition.transferTaxPercentage}
										onPick={this.onPlayerIn}
										action
										showHeader={false}
										size={10}
									/>
								</Element>
							</Block>
						</Col>
					</Row>) || null
				}
			</React.Fragment>
		);
	}
}

const WrappedComponent = AbstractTeam(TransfersContainer);


export default WrappedComponent;
