import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import moment from 'moment';
import { Player, AbstractTeamType, UserState } from './../../types';
import {
	Title, TitleType, PlayerList, Team, Substitutes, PlayerType, Row, Col, Block,
	TeamOverview, Select, Button, Input, AbstractTeam, Alert
} from './../../components';
import { Icon } from 'antd';
import { theme } from '../../styles/theme-goudenspelen';
import { startingListToPositionsList } from './../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import {EditableTeamNameStyle} from './../../components/Dashboard/DashboardStyles';
import {SaveLineupButton} from './../Team/TeamStyles';
import {NewTeamStyles} from './../NewTeam/NewTeamStyles';

import { Element, scroller } from 'react-scroll'

const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/goudenspelen-cycling-lineup.png';

export interface Props {
	match: any;
	user: UserState;
}

export interface State {
	notFound: boolean;
}

class EditTeamContainer extends Component<AbstractTeamType & Props, State> {
	constructor(props: AbstractTeamType & Props) {
		super(props);

		this.state = {
			notFound: false,
		}
	};

	onPlaceHolderClick = (e: any) => {
		scroller.scrollTo('all-players', {
			duration: 1000,
			delay: 100,
			smooth: true,
			offset: 50
		})
	};

	componentDidMount() {
		const teamId = this.props.match.params.id;
		this.getTeamInfo(teamId)
	}

	componentDidUpdate(prevProps: AbstractTeamType & Props) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.getTeamInfo(this.props.match.params.id);
		}
	}

	getTeamInfo = (teamId: number) => {
		const playerProps =
		['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname'];
		const selectionProps: any[] = [];
		teamsActions.get(teamId)
			.then(result => {
				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => Object.assign({ inStarting: true }, pick(player, playerProps), pick(player.selection, selectionProps)));
				const bench = result.players
					.filter((player: any) => player.selection.positionId === 0)
					.map((player: any) => Object.assign({ inStarting: false }, pick(player, playerProps), pick(player.selection, selectionProps)));
				const team = result.team;
				const teamName = result.team.name;

				const captainId = result.players
					.find((player: any) => player.selection.captain === 1)
					.id;

				const budget = result.players
					.reduce((acc: any, player: any) => acc - player.value, this.props.application.competition.budget);

					this.props.initTeamState(starting, bench, team, teamName, captainId, budget);
			})
			.catch(error => {
				this.setState({ notFound: true });
			})
	};

	render() {
		const {
			application, 
			starting, 
			bench, 
			captainId, 
			teamName, 
			budget, 
			matches, 
			user, 
			teamNameChanged,
			initializedExternally
		} = this.props;
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows);
		const totalPlayersToPick = application.competition.lineupSize + application.competition.benchSize;
		const startingPicked = starting
			.filter(player => !!player);
		const benchPicked = bench
			.filter(player => !!player);
		const totalPlayersPicked = startingPicked.length + benchPicked.length;
		const teamId = parseInt(this.props.match.params.id);

		const team = this.props.user && 
			this.props.user.teams && 
			this.props.user.teams.find((team: any, index:number) => team.id === teamId);

		const firstPlayingWeekPassed = team && team.id && matches && matches.info && matches.info.deadlineWeek
			&& (matches.info.deadlineWeek > team.weekId);
			//&& (matches.info.deadlineWeek > application.competition.competitionStartingWeek);
		const notTeamOwner = team && team.userId && user.data && (team.userId !== user.data.id);

		const deadlineDate = this.props.matches && this.props.matches.info && this.props.matches.info.deadlineDate;
		const deadlineWeek = (this.props.matches && this.props.matches.info && this.props.matches.info.deadlineWeek) || 0;
		const deadlineEpochMilliseconds = deadlineDate ? moment(deadlineDate).valueOf() : 0;

		const noMarginOrPaddingStyle = {  };

		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';

		return (
			<Block>
				{(notTeamOwner || this.state.notFound) && <Redirect
					to={{ pathname: `/home` }}
				/>}
				{firstPlayingWeekPassed && team && <Redirect
					to={{ pathname: `/transfers/${team.id}` }}
				/>}

				{
					(team && initializedExternally && <Row>
						<Col md={15} sm={15} xs={24} style={{margin: 0, padding: "0 20px 0 0"}} className="left">
							<Row>
								<TeamOverview budget={budget}
									totalPlayers={totalPlayersToPick}
									deadlineEpochMilliseconds={deadlineEpochMilliseconds}
									deadlineWeek={deadlineWeek}
									totalPlayersSelected={totalPlayersPicked} />

									<Alert style={{ textAlign: 'center', marginTop: 10 }}
									message="Je kan dit nieuwe team onbeperkt wijzigen tot de start van de eerstvolgende wedstrijd. Klik op een speler/renner, verwijder deze uit je team en selecteer een vervanger uit de lijst. Vergeet niet te bewaren!"
									type="info"
									showIcon />

									{
										(team && <SaveLineupButton style={{display: 'inline', float: 'right'}}>
											<Button
												onClick={(e: any) => this.props.onTeamEdit(team.id)}
												type="primary"
												size="large">
												<Icon type="save" theme="filled" />
												Bewaar team
											</Button>
										</SaveLineupButton>) || null
									}

									<Input
										onChange={this.props.onTeamNameChange}
										value={teamName}
										style={{ margin: '10px auto', maxWidth: 250, backgroundColor: '#fff' }}
									/>
									{
										(teamNameChanged &&
											<div style={{ textAlign: 'right', display: 'inline-block' }}>
												<EditableTeamNameStyle>
													<Icon type="close-circle" onClick={this.props.resetTeamName} theme="filled" />
													<Icon type="check-circle" onClick={(e: any) => this.props.onTeamNameUpdate(team.id)} theme="filled" />
												</EditableTeamNameStyle>
											</div>
										) || null
									}

								{/* <Row style={{  }}>
									<Col lg={8} md={8} sm={8} xs={8}>
										<img style={{ transform: 'translateY(50px)', width: '70%', height: 'auto' }} src="https://goudenspelen.s3-eu-west-1.amazonaws.com/files/minerva-logo.jpg" alt="Minerva"></img>
									</Col>
									<Col lg={8} md={8} sm={8} xs={8}>
									</Col>
									<Col lg={8} md={8} sm={8} xs={8}>
										<img style={{ transform: 'translateY(50px)', width: '70%', height: 'auto', float: 'right' }} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/rowenta-logo.png" alt="Rowenta"></img>
									</Col>
								</Row> */}
								</Row>
								<Team
									widthRatio={15}
									heightRatio={13}
									clubs={this.props.clubs.data}
									centerAligned={true}
									bg={teamBackground}
									selection={startingByPositions}
									assetsCdn={application.competition.assetsCdn}
									playerType={PlayerType.Cycling}
									captainId={captainId}
									playerBadgeColor={theme.primaryColor}
									playerBadgeBgColor={theme.primaryColor}
									showPlayerValueInsteadOfPoints={true}
									onCaptainSelect={this.props.onCaptainSelect}
									onSwap={this.props.onPlayerSwap}
									swapPlayerId={this.props.swapPlayerId}
									swappedFrom={this.props.swappedFrom}							
									modalEnabled={true}
									onRemove={(player: Player) => this.props.removeStartingPlayer(player)}
									onPlaceholderClick={this.onPlaceHolderClick}
									playerPointsColor="#ffffff"
									playerPointsBgColor="#84FF00" />
								{	(this.props.application.competition.benchSize) ?
									<Substitutes
										selection={bench}
										clubs={this.props.clubs.data}
										title="De bus"
										bgColor="#F0F3F8"
										playerType={PlayerType.Cycling}
										playerBadgeBgColor={theme.primaryColor}
										modalEnabled={true}
										assetsCdn={application.competition.assetsCdn}
										onPlaceholderClick={this.onPlaceHolderClick}
										onSwap={this.props.onPlayerSwap}
										swapPlayerId={this.props.swapPlayerId}
										swappedFrom={this.props.swappedFrom}
										showPlayerValueInsteadOfPoints={true}
										playerBadgeColor="#000"
										playerPointsColor="#ffffff"
										playerPointsBgColor="#292929"
										onRemove={(player: Player) => this.props.removeBenchPlayer(player)}
									/>
									: null
								}
						</Col>
						<Col md={9} sm={9} xs={24} style={{margin: 0, padding: 0}} className="right">
								<Element name="all-players">
									<PlayerList
										clubs={this.props.clubs.data}
										isLoading={this.props.players.isFetching}
										hidePositions={true}
										assetsCdn={application.competition.assetsCdn}
										isPickAble={this.props.isPickAble}
										playerType={PlayerType.Cycling}
										data={this.props.players.data}
										onPick={this.props.pickPlayer}
										action
										showHeader={false}
										size={10}
									/>
								</Element>
						</Col>
					</Row>) || null
				}
			</Block>
		);
	}
}

const WrappedComponent = AbstractTeam(EditTeamContainer);


export default WrappedComponent;