import { FootballPositionIds } from './constants';

function startingListToPositionsList(starting: any, rowConfig: number[], type?: string) {
	const result = [];

	if (type === 'football') {
		const GoalKeeper = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Goalkeeper);
		const Defenders = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Defender);
		const Midfielders = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Midfielder);
		const Attackers = starting.filter((player: any) => player && player.positionId === FootballPositionIds.Forward);

		result.push(GoalKeeper, Defenders, Midfielders, Attackers);
	} else {
		const rows = rowConfig.length;
		for (let row = 0; row < rows; row++) {
			const totalPickedSoFar: number = result
				.map(items => items.length)
				.reduce((acc, item) => acc + item, 0);

			const lastPickedIndex: number = totalPickedSoFar;
			const sliceFrom: number = row === 0 ? 0 : lastPickedIndex;
			const sliceTo: number = row === 0 ? rowConfig[row] : lastPickedIndex + rowConfig[row];
			const items: any[] = starting.slice(sliceFrom, sliceTo);
			result.push(items);
		}
	}
	return result;
}

function getPlayerPositionHexColor(player: any, theme: any) {
	const defaultHex = '#000';

	if (!player) {
		return defaultHex;
	}

	if (player.positionId === 1) {
		return theme.positionGk;
	} else if (player.positionId === 2) {
		return theme.positionDf;
	} else if (player.positionId === 3) {
		return theme.positionMf;
	} else if (player.positionId === 4) {
		return theme.positionFw;
	} else {
		return defaultHex;
	}
}

function firstLetterUppercased(string: string) {
	return string.charAt(0).toUpperCase();
}

function roundNextHalf(number: number) {
	const integerPart = Math.floor(number);
	const decimalPart = number - integerPart;

	if (decimalPart === 0) {
		return integerPart;
	} else if (decimalPart <= 0.5) {
		return integerPart + 0.5;
	} else {
		return integerPart + 1;
	}
}

//EDIT FOR EVERY GAME
const rankingMapping = {
	'19': 'general',
	'20': 'week',
	'22': 'wereldbeker',
	'21': 'superprestige',
	'23': 'league',
	'24': 'league-week',
	'25': 'general',
	'26': 'week',
	'27': 'de-dubbel',
	'28': 'peloton',
	'29': 'peloton-week',
};

function getImageUrl(fileName: string) {
	return `https://fanarena-admin.s3-eu-west-1.amazonaws.com/${fileName}`
}

function getPrizes(prizesWithTranslations: any) {
	const prizesMap = {} as any;

	const prizesList = prizesWithTranslations.map((prizeData: any) => {
		const translatedPrize = prizeData.translations.find((translation: any) => translation.languageCode === 'nl');
		let imageURL = null;

		if (prizeData.image && prizeData.image.fileName) {
			imageURL = getImageUrl(prizeData.image.fileName);
		}
		return Object.assign(
			{ firstRank: prizeData.firstRank, lastRank: prizeData.lastRank, },
			translatedPrize || {},
			{ sort: prizeData.sort, image: imageURL, ranking: prizeData.ranking }
		);
	});

	const rankingsValues = Object.keys(rankingMapping);
	const prizesGrouped = rankingsValues
		.map(rankingValue => {
			//@ts-ignore
			const key: any = rankingMapping[rankingValue as any];
			return {
				[key]: prizesList
					.filter((prizeItem: any) => prizeItem.ranking === rankingValue)
					.sort((firstPrize: any, secondPrize: any) => firstPrize.sort - secondPrize.sort)
			};
		})
		.reduce((acc, item) => ({ ...acc, ...item }), {});

	return prizesGrouped;
}

function getNumberOfExtraClubPlayers(players: any, teamSameClubPlayersLimit: any) {
	const clubsIds: any[] = [];
	let extraPlayers = 0;

	players
		.forEach((player: any) => {
			if (player && !clubsIds.includes(player.clubId)) {
				clubsIds.push(player.clubId);
			}
		});

	clubsIds
		.forEach(clubId => {
			const totalClubPlayers = players.filter((player: any) => player && player.clubId === clubId).length;
			if (totalClubPlayers > teamSameClubPlayersLimit) {
				extraPlayers += totalClubPlayers - teamSameClubPlayersLimit;
			}
		});
	return extraPlayers;
}

export {
	startingListToPositionsList,
	firstLetterUppercased,
	roundNextHalf,
	getPlayerPositionHexColor,
	getPrizes,
	getNumberOfExtraClubPlayers
}