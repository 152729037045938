import React, { Component } from 'react';
import { ContainerStyle, TableStyle } from './../PlayerList/PlayerListStyle';
import { Icon } from 'antd';
import Button from '../UI/Button/Button';
import Link from '../UI/Link/Link';
import * as leaguesActions from '../../actions/leagues';

export interface LeaguesListProps {
	data: any[];
	size: number;
	isLoading?: boolean;
	showHeader?: boolean;
	leagueNameAsLink?: boolean;
	onView?: any;
	isRowExpandable?: boolean;
	noLeaguesMessage?: any;
	onJoin?: any;
	// t: any;
	joinIgnoreLeagueIds?: number[] | undefined
}

export interface LeaguesListState {
	expandedLeaguesInfo: any;
}

const tableTextEllipsis = { maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' };

class LeaguesList extends Component<LeaguesListProps, LeaguesListState> {
	state = {
		expandedLeaguesInfo: []
	};

	customExpandIcon(props: any) {
		if (props.expanded) {
			return <a style={{ color: 'black', padding: 10 }} onClick={(e: any) => {
				props.onExpand(props.record, e);
			}}><Icon type="caret-up" /></a>
		} else {
			return <a style={{ color: 'black', padding: 10 }} onClick={(e: any) => {
				props.onExpand(props.record, e);
			}}><Icon type="caret-down" /></a>
		}
	}

	_onExpand = (expanded: any, record: any) => {
		const infoAlreadyFetched = this.state.expandedLeaguesInfo
			.find((item: any) => item.league.id === record.id);

		if (!infoAlreadyFetched) {
			leaguesActions.fetchLeagueInfo(record.id)
				.then((info: any) => {
					this.setState({ expandedLeaguesInfo: [...this.state.expandedLeaguesInfo, info] })
				});
		}
	};

	render() {
		const { data, size, showHeader, isLoading, leagueNameAsLink, isRowExpandable} = this.props;
		const { expandedLeaguesInfo } = this.state;
		const extraTableProps = {} as { expandedRowRender?: any, expandIcon?: any, onExpand?: any };

		if (isRowExpandable) {
			const expandableRowTableColumns = [
				{
					title: '#',
					key: 'recordNumber',
					dataIndex: 'recordNumber',
					width: '20%',
					ellipsis: true,
					render: (text: string, record: any, index: number) => {
						return <b>{index + 1}</b>;
					}
				},
				{
					key: 'name',
					title: '',
					width: '40%',
					dataIndex: 'name',
					ellipsis: true,
					render: (text: string, record: any) => {
						return <div style={{ maxWidth: '160px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}> <b>{record.name}</b> </div>
					}
				},
				{
					key: 'user',
					title: '',
					width: '40%',
					dataIndex: 'user',
					ellipsis: true,
					render: (text: string, record: any) => {
						return <b>{`${record.user.firstName} ${record.user.lastName}`}</b>
					}
				}
			]


			extraTableProps.expandedRowRender = (record: any) => {
				const leagueTeams: { teams?: any[], league?: any } =
					expandedLeaguesInfo.find((item: any) => item.league.id === record.id) || { teams: [], league: {} };

				return leagueTeams && leagueTeams.teams && leagueTeams.teams.length ?
					<TableStyle
						columns={expandableRowTableColumns}
						dataSource={leagueTeams.teams}
						showHeader={showHeader}
						locale={{ emptyText: 'Geen teams in dit peloton.' }}
						pagination={false}
						rowKey="id"
					/> :
					null;
			};
			extraTableProps.expandIcon = (props: any) => this.customExpandIcon(props);
			extraTableProps.onExpand = this._onExpand;
		}

		const columns = [{
			key: 'name',
			title: '',
			width: '50%',
			dataIndex: 'name',
			ellipsis: true,
			render: (text: string, record: any) => {
				return leagueNameAsLink ?
					<Link to={`/leagues/${record.id}`}>{text}</Link> || null :
					<b>{text}</b>
			}
		}, {
			key: 'action',
			title: 'View league',
			dataIndex: 'action',
			width: '40%',
			ellipsis: true,
			render: (text: string, record: any) => {
				const joinIgnoreLeagueIds = this.props.joinIgnoreLeagueIds || [];

				return (
					this.props.onView &&
					<Button
						onClick={(e: any) => this.props.onView(record)}
						size="small">
						View
						</Button>
				) || (
						this.props.onJoin && joinIgnoreLeagueIds.indexOf(record.id) === -1 &&
						<Button
							onClick={(e: any) => this.props.onJoin(record)}
							type={'primary'}
							size="small">
							Doe mee
						</Button>
					) ||
					<span />;
			}
		}];

		return (
			<ContainerStyle>
				<TableStyle
					columns={columns}
					dataSource={data}
					showHeader={showHeader}
					locale={{ emptyText: this.props.noLeaguesMessage || 'Deze ploeg neemt nog niet deel aan een league' }}
					loading={isLoading}
					pagination={{ pageSize: size }}
					rowKey="id"
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
					{...extraTableProps}
				/>
			</ContainerStyle>
		);
	}
}

export default LeaguesList;
