import React, {Component} from 'react';
import {ContainerStyle, BasicTableStyle} from './../PlayerList/PlayerListStyle';
import Button from '../UI/Button/Button';
import Link from '../UI/Link/Link';

export interface TeamsListProps {
	data: any;
	size: number;
	isLoading?: boolean;
	showHeader?: boolean;
	isAdmin: boolean;
	adminTeamId?: number | null;
	onTeamKick: any;
	matches: any;
}

export interface TeamsListState {
}

class TeamsList extends Component<TeamsListProps, TeamsListState> {
	state = {};

	render() {
		const {data, size, showHeader, isLoading, isAdmin, adminTeamId} = this.props;
		const teamsWithMetaData = data.map((team: any, index: number) =>
			Object.assign({}, team, {rank: index + 1}));

		const columns = [{
			title: '#',
			key: 'rank',
			dataIndex: 'rank',
			width: '10%',
			render: (text: string, record: any) => {
				return <b>{text}</b>
			}
		}, {
			key: 'name',
			title: 'Naam',
			width: '50%',
			dataIndex: 'name',
			render: (text: string, record: any) => {
				return <React.Fragment>
					<Link to={`/public/${record.id}`}> <b>{text}</b></Link>
					<span style={{display: 'block', fontSize: '12px'}}>
						{`${record.user.firstName} ${record.user.lastName}`}
						</span>
				</React.Fragment>;
			}
		}, {
			key: 'points',
			title: 'Punten',
			width: '20%',
			dataIndex: 'points',
			render: (text: string, record: any) => {
				return <b>{text === null ? '0' : text}</b>
			}
		}];

		if (isAdmin) {
			columns.push({
				key: 'action',
				title: '',
				width: '20%',
				dataIndex: 'action',
				render: (text: string, team: any) => {
					const canLeave = team && team.weekId === this.props.matches.info.deadlineWeek;

					return (canLeave && adminTeamId && adminTeamId !== team.id && <Button
						onClick={(e: any) => this.props.onTeamKick && this.props.onTeamKick(team)}
						size="small">
						Verwijder
					</Button>) || <span/>;
				}
			});
		}

		return (
			<ContainerStyle>
				<BasicTableStyle
					columns={columns}
					dataSource={teamsWithMetaData}
					showHeader={showHeader}
					locale={{emptyText: 'Deze peloton heeft nog geen ploegen.'}}
					loading={isLoading}
					pagination={{pageSize: size}}
					rowKey="id"
					rowClassName={(record: object, index: number) => index % 2 ? 'ant-table-row--odd' : 'ant-table-row--even'}
				/>
			</ContainerStyle>
		);
	}
}

export default TeamsList;
