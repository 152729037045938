/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { TeamStyle, Sponsor } from './TeamStyle';
import Player, { PlayerType } from '../Player/Player';
import {Club} from './../../types';
import { Row, Col } from 'antd';

export interface TeamState {
}

export interface TeamProps {
	bg: string;
	bgSponsor?: string;
	widthRatio: number;
	heightRatio: number;
	playerType: PlayerType;
	selection: any;
	playerBadgeColor: string;
	playerBadgeBgColor: string;
	playerPointsColor: string;
	playerPointsBgColor: string;
	onRemove?: any;
	onSwap?: any;
	isSwapAble?: any;
	onCaptainSelect?: any;
	modalEnabled? : boolean;
	onPlaceholderClick?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	assetsCdn: string;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerStatsPoints?: boolean;
	clubs: Club[];
	showPlayerValueInsteadOfPoints?: boolean;
	captainId?: number;
	centerAligned?: boolean | undefined;
}

class Team extends Component<TeamProps, TeamState> {
	render() {
		const {
			bg,
			bgSponsor,
			widthRatio,
			heightRatio,
			selection,
			playerType,
			playerBadgeColor,
			playerBadgeBgColor,
			playerPointsColor,
			playerPointsBgColor,
			onRemove,
			onSwap,
			isSwapAble,
			onPlaceholderClick,
			swapPlayerId,
			swappedFrom,
			assetsCdn,
			actionLessPlayerIds,
			showPlayerValue,
			showPlayerStatsPoints,
			showPlayerValueInsteadOfPoints,
			clubs,
			onCaptainSelect,
			modalEnabled,
			captainId,
			centerAligned
		} = this.props;

		return (
			<Row><Col>
				<TeamStyle bg={bg} widthRatio={widthRatio} heightRatio={heightRatio} centerAligned={centerAligned}>
					{/* {bgSponsor && <Sponsor bg={bgSponsor} widthRatio={widthRatio} heightRatio={heightRatio} />} */}
					{selection.map((position: any, positionIndex: number) => {
						const sportSpecificProps: { shirtCycling?: string, club?: Club } = {};

						return (
							<div className={`position`} key={`positionIndex-${positionIndex}`}>
								{
									position.map((player: any, playerIndex: number) => {

										if ((playerType === PlayerType.Cycling || playerType === PlayerType.Cyclocross) && player) {
											const club = clubs.find((item: Club, index: number) => item.id === player.clubId);

											sportSpecificProps.shirtCycling = `${assetsCdn}/jerseys/cycling/${player.clubId}.png`;
											sportSpecificProps.club = club;
										}

										return <Row>
												{/* <img style={{width: "80px", height: "auto"}} src="https://goudenspelen.s3-eu-west-1.amazonaws.com/files/minerva-logo.jpg" alt="Minerva"></img> */}
												<Player
													key={`playerIndex-${playerIndex}`}
													pointsColor={playerPointsColor}
													pointsBgColor={playerPointsBgColor}
													badgeColor={playerBadgeColor}
													badgeBgColor={playerBadgeBgColor}
													modalEnabled={modalEnabled}
													captainId={captainId}
													player={player}
													showPlayerValue={showPlayerValue}
													showPlayerStatsPoints={showPlayerStatsPoints}
													type={playerType}
													onRemove={onRemove}
													showPlayerValueInsteadOfPoints={showPlayerValueInsteadOfPoints}
													onSwap={onSwap}
													isSwapAble={isSwapAble}
													onCaptainSelect={onCaptainSelect}
													onPlaceholderClick={onPlaceholderClick}
													swapPlayerId={swapPlayerId}
													actionLessPlayerIds={actionLessPlayerIds}
													swappedFrom={swappedFrom}
													{...sportSpecificProps}
												/>
												{/* <img style={{width: "80px", height: "auto"}} src="https://goudenspelen.s3-eu-west-1.amazonaws.com/files/minerva-logo.jpg" alt="Minerva"></img> */}
											</Row>
									})
								}
							</div>
						);
					})}
				</TeamStyle>
				<div style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: 2}}>
					<div style={{position: "absolute", top: "60px", left: "0", width: "20%"}}>
						{/* <img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-wonderbox.png" alt="Wonderbox"></img>
						<img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img> */}
						{/* <a href='https://www.vivabox.be/nl?utm_source=DPGmedia&utm_medium=logo&utm_campaign=BE' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-vivabox.png" alt="Vivabox"></img></a> */}
						{/* <a href='http://minerva.be/nl/' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img></a> */}
					</div>
					<div style={{position: "absolute", top: "20px", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"}}>
						{/* <img style={{width: "25%", padding: "10px"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-hln-red.png" alt="HLN"></img> */}
						{/* <a href='http://minerva.be/nl/' target='_blank'><img style={{maxHeight: "50px", marginTop: "10px"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img></a> */}

						<img style={{width: "40%", padding: "10px"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-hln-red.png" alt="HLN"></img>
					</div>
					<div style={{position: "absolute", top: "60px", right: "0", width: "20%"}}>
						{/* <a href='https://be.wonderbox.com/nl?utm_source=DPGmedia&utm_medium=logo&utm_campaign=BE' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-wonderbox.png" alt="Wonderbox"></img></a> */}
						{/* <a href='http://minerva.be/nl/' target='_blank'><img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img></a> */}
						{/* <img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-minerva.png" alt="Minerva"></img>
						<img style={{width: "100%"}} src="https://goudenspelen.s3.eu-west-1.amazonaws.com/files/logo-vivabox.png" alt="Vivabox"></img> */}
					</div>
				</div>
				</Col>
			</Row>
		);
	}
}

export default Team;