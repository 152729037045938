import React, { Component } from 'react';
import { Icon } from 'antd';

import { TeamPointsModalStyle } from './TeamPointsModalStyle';
import { Team } from './../..';
import { Player, Club, UserState, ClubsState, ApplicationState } from '../../types';
import { ModalProps } from "antd/lib/modal";
import { PlayerBg } from '../Player/PlayerStyle';
import { Row, Col, PlayerType } from '..';
import { DeleteButtonSvg, CaptainButtonSvg, SwapButtonSvg, RollBackSvg } from '../../styles/custom-icons';
import { startingListToPositionsList } from '../../lib/helpers';
import { pick } from 'lodash';
import * as teamsActions from '../../actions/teams'
import { theme } from './../../styles/theme-goudenspelen';

// import teamBackground from './../../assets/img/bg-football.png';
const teamBackground = 'https://goudenspelen.s3-eu-west-1.amazonaws.com/files/goudenspelen-cycling-lineup.png';
import teamSponsor from "./../../assets/img/header/megaspelen-logo.png";

const ExitIcon = (props: any) => <Icon component={DeleteButtonSvg} {...props} />;

export interface TeamPointsModalState {
	starting: Player[];
	captainId: number;
	teamName: string;
}

export interface TeamPointsModalProps {
	teamId: number;
	weekId: number;
	visible: boolean;
	onCancel: any;
	user: UserState;
	clubs: ClubsState;
	application: ApplicationState;
	selectedWeeklyWinner: any;
}

class TeamPointsModal extends Component<TeamPointsModalProps, TeamPointsModalState> {

	state = {
		starting: [],
		captainId: -1,
		teamName: ''
	}

	componentDidMount() {
		this.getTeamInfo(this.props.weekId);
	}

	componentDidUpdate(prevProps: TeamPointsModalProps) {
		if ((this.props.teamId && (this.props.teamId !== prevProps.teamId)) || (this.props.weekId && (this.props.weekId !== prevProps.weekId))) {
			this.setState({ starting: [], captainId: -1, teamName: '' });
			this.getTeamInfo(this.props.weekId);
		}
	}

	onCancel = (e: any) => {
		this.props.onCancel(e);
	};

	getTeamInfo = (pointsWeekId: number) => {
		const { teamId } = this.props;
		const playerProps =
			['id', 'name', 'short', 'positionId', 'clubId', 'value', 'ban', 'injury', 'form', 'forename', 'surname', 'stats'];
		const selectionProps: any[] = ['points'];

		Promise.all([teamsActions.points(teamId, pointsWeekId)])
			.then(([result]) => {
				const weekStat = result.weekStat.find((stat: any) => stat.weekId === pointsWeekId);

				const teamPointsInfo = {
					generalPoints: result.team.points !== null ? result.team.points : '-',
					generalRank: result.team.rank !== null ? result.team.rank : '-',
					visibleWeekPoints: (weekStat && weekStat.points) || '-',
					visibleWeekRank: (weekStat && weekStat.rank) || '-'
				};

				const starting = result.players
					.filter((player: any) => player.selection.positionId === 1)
					.map((player: any) => {
						const playerStats = player.stats && player.stats[0];

						return Object.assign({ inStarting: true }, pick(player, playerProps), pick(player.selection, selectionProps))
					});

				const captainId = result.players
					.find((player: any) => player.selection.captain === 1)
					.id;

				const teamName = result.team.name;

				this.setState({ starting, captainId, teamName });
			})
			.catch(error => {
			})
	};

	render() {
		const { visible, application, user, weekId, selectedWeeklyWinner } = this.props;
		const { captainId, starting, teamName } = this.state;

		const userRegisterInfo = (this.props.user && this.props.user.data && this.props.user.data.registerInfo) || '';
		const startingByPositions = startingListToPositionsList(starting, application.competition.lineupPositionRows);

		const title = <div className={'custom-title-container'}>
			{teamName ?
				<span className="text-title">
					{`${selectedWeeklyWinner.firstName} ${selectedWeeklyWinner.lastName}`} - SPEELDAG WINNAAR {weekId}
				</span>
				: null
			}
			<ExitIcon onClick={this.onCancel} />
		</div>;

		return (
			<TeamPointsModalStyle
				title={title}
				closable={false}
				visible={this.props.visible}
				onCancel={this.onCancel}
				footer={[]}
			>
				<Team widthRatio={15}
					heightRatio={13}
					clubs={this.props.clubs.data}
					bg={teamBackground}
					captainId={captainId}
					centerAligned={true}
					selection={startingByPositions}
					assetsCdn={application.competition.assetsCdn}
					showPlayerStatsPoints={false}
					modalEnabled={false}
					playerType={PlayerType.Cycling}
					playerBadgeColor={theme.primaryColor}
					playerBadgeBgColor={theme.primaryColor}
					playerPointsColor="#fff"
					playerPointsBgColor="#84FF00" />
			</TeamPointsModalStyle>
		)
	}
}


export default TeamPointsModal;