import React, {Component} from 'react';
import {PlayerStyle, PlayerBg, SoccerJersey, Badge, Points, NoPlayer, TopRightAction, Value, PointsBadge} from './PlayerStyle';
import {Icon} from 'antd';
import {theme} from "../../styles/theme-goudenspelen";
import {firstLetterUppercased} from './../../lib/helpers';
import {PlayerModal} from './../../components';
import {Club} from './../../types';
import {AddButtonSvg, CaptainButtonSvg} from './../../styles/custom-icons';

const AddIcon = (props: any) => <Icon component={AddButtonSvg} {...props} />;
const CaptainIcon = (props: any) => <Icon component={CaptainButtonSvg} {...props} />;

export enum PlayerType {
	Cycling = "Cycling",
	Cyclocross = "Cyclocross",
	SoccerShirt = "SoccerShirt",
	SoccerPortrait = "SoccerPortrait"
};

export interface PlayerState {
		modalVisible: boolean;
}

export interface PlayerProps {
	type: PlayerType;
	bgShirt?: string;
	bgPortrait?: string;
	bgJersey?: string;
	player: any;
	badgeColor: string;
	badgeBgColor: string;
	pointsColor: string;
	pointsBgColor: string;
	shirtCycling?: string;
	shirtSoccer?: string;
	portraitFace?: string;
	soccerJersey?: string;
	onRemove?: any;
	isSwapAble?: any;
	modalEnabled?: any;
	onPlaceholderClick?: any;
	swapPlayerId?: number | null;
	swappedFrom?: string | null;
	onSwap?: any;
	onCaptainSelect?: any;
	actionLessPlayerIds?: any[];
	showPlayerValue?: boolean;
	showPlayerStatsPoints?: boolean;
	showPlayerValueInsteadOfPoints?: boolean;
	benchPlayer?: boolean;
	club?: Club;
	captainId?: number;
}

class Player extends Component<PlayerProps, PlayerState> {
	state = {
		modalVisible: false
	}

	onCancel = (event: any) => {
		event.stopPropagation();
		this.setState({modalVisible: false});
	}

	onPlayerClick = (showModal?: boolean) => {
		if(this.props.player && this.props.player.id && showModal) {
			this.setState({modalVisible: true});
		}
	}

	render() {
		const {
			type, bgShirt, player, badgeColor, badgeBgColor, pointsColor, pointsBgColor,
			shirtCycling, shirtSoccer, portraitFace, soccerJersey, onRemove, onSwap, isSwapAble, swapPlayerId, swappedFrom,
			onPlaceholderClick, actionLessPlayerIds, showPlayerValue, benchPlayer, showPlayerValueInsteadOfPoints,
			modalEnabled, club, onCaptainSelect, captainId, showPlayerStatsPoints
		} = this.props;

		const {modalVisible} = this.state;

		const topRightActionIconStyle = {
			fontSize: '12px',
			width: '12px',
			height: '12px',
			color: '#000',
			backgroundColor: theme.primaryColor,
			position: 'absolute' as 'absolute',
			left: '4px',
			top: '4px',
			textAlign: 'center' as 'center'
		};

		const ignoredPlayersIdsForActions = actionLessPlayerIds || [];

		const isCaptain = player && player.id === captainId; 

		const swappedAlreadyFromPlayerArea = onSwap && swapPlayerId && player && (swappedFrom === 'starting' && player.inStarting) && swapPlayerId !== player.id;


		const playerName = (player && `${player.surname} ${player.forename && firstLetterUppercased(player.forename)}.`) || 'Kies renner';

		const playerHasAction = player && player.id && ignoredPlayersIdsForActions.indexOf(player.id) === -1;
		const pointsEnabled = (player && player.points >= 0) || showPlayerValueInsteadOfPoints;
		const hasModal = !!modalEnabled;

		const hasInactiveOverlay = swappedAlreadyFromPlayerArea || (swapPlayerId && !swappedAlreadyFromPlayerArea);

		if (player && showPlayerStatsPoints) {
			const weekStatsPoints = player.stats.reduce((acc: number, stat: any) => acc + stat.points, 0);
			const weekStatsPointsFactor = player.id === captainId ? 1.5 : 1;
			player.points = weekStatsPoints * weekStatsPointsFactor;
		}

		return (
			<PlayerStyle onClick={(e: any) => this.onPlayerClick(!hasInactiveOverlay)}>
				{player && (type === PlayerType.Cycling || type === PlayerType.Cyclocross) && <PlayerBg bg={shirtCycling} inactive={swappedAlreadyFromPlayerArea}/>}
				{player && type === PlayerType.SoccerShirt && <PlayerBg bg={shirtSoccer}/>}
				{player && type === PlayerType.SoccerPortrait && <PlayerBg bg={portraitFace}/>}
				{player && type === PlayerType.SoccerPortrait && <SoccerJersey bg={soccerJersey}/>}
				{
				<Badge color={badgeColor} bgColor={badgeBgColor}>
					<h4>{playerName}</h4>
				</Badge>
				}

				{
					pointsEnabled ? 
						<PointsBadge color={'#FFF'} bgColor={'#292929'}>
								<p>
									{player && player.points ? `${player.points} ptn` : (showPlayerValueInsteadOfPoints ? `€${(player && player.value) || 0}M` : '-' )}
								</p>
						</PointsBadge> : null
				}

				{
					(
						player && showPlayerValue &&
						<Value benchPlayer={benchPlayer} color={theme.primaryColor}>
							<h4>€{player.value}M</h4>
						</Value>
					) || null
				}

				{(!player &&
					<NoPlayer onClick={onPlaceholderClick || (() => {
					})}>
						<AddIcon
									style={{fontSize: '2em', color: badgeBgColor, cursor: 'pointer'}}/>
					</NoPlayer>) || null}
				{(!hasModal && player && playerHasAction && onRemove &&
					<TopRightAction onClick={(e: any) => {
							e.stopPropagation();
							onRemove(player);
						}} bgColor={theme.primaryColor}>
						<Icon type="close" style={topRightActionIconStyle}/>
					</TopRightAction>) || null}
				{(!hasModal && player && onSwap && !swappedAlreadyFromPlayerArea && swapPlayerId !== player.id &&
					<TopRightAction onClick={(e: any) => onSwap(player)} bgColor={theme.primaryColor}>
						<Icon type="swap" style={topRightActionIconStyle}/>
					</TopRightAction>) || null}
				{(!hasModal && player && onSwap && (swapPlayerId && swapPlayerId === player.id) &&
					<TopRightAction onClick={(e: any) => onSwap(player)} bgColor={theme.primaryColor}>
						<Icon type="rollback" style={topRightActionIconStyle}/>
					</TopRightAction>) || null}

				{(player && isCaptain &&
					<TopRightAction bgColor={theme.primaryColor}>
						<CaptainIcon style={{fontSize: 18}}/>
					</TopRightAction>) || null}

				{
					player && club ? 
					<PlayerModal 
						visible={modalVisible} 
						club={club}
						swapPlayerId={swapPlayerId}
						onCaptainSelect={onCaptainSelect}
						onRemove={onRemove}
						onSwap={onSwap}
						isSwapAble={isSwapAble}
						shirtCycling={shirtCycling}
						player={player} 
						onCancel={this.onCancel}/> :
						null
				}
			</PlayerStyle>
		)
	}
}

export default Player;